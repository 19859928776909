.Footer {
	background-color: black;
	color: rgba(255, 255, 255, 0.521);
	padding-top: 0%;
	padding-bottom: 0.8%;
	padding-right: 3%;
	padding-left: 3%;
}

.Footer__copy-right {
	font-size: 1.2rem;
	text-align: center;
	margin: 0;
}

.Footer__links {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	justify-content: center;
	list-style: none;
	padding-left: 0;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-bottom: 0% !important;
}

.Footer__link {
	font-size: 1.2rem;
}

.Footer__link a:hover {
	border: none;
	text-decoration: none;
	color: #fff;
	font-weight: 300;
}

.Footer__link::after {
	content: ' ';
	margin-right: 3px;
	margin-left: 3px;
	color: #fff;
	font-weight: 600;
}
/* 
.Footer__link:not(:last-child)::after {
	content: '|';
	margin-right: 3px;
	margin-left: 3px;
	color: #fff;
	font-weight: 600;
} */

.USFlagLogo {
	padding-top: 6px;
	display: block;
	margin-left: 5px;
}

.USFlagLogo img {
	height: 15px;
	width: 25px;
}
