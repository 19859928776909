:root {
	--link-bold-color: #1a237e;
}

html {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	position: relative;
	margin: 0;
	padding: 0;
	height: 100%;
}

.headerLeft {
	width: 100%;
	margin-top: 0;
	float: left;
}

.formik-error {
	color: #b50202;
}

*,
*::after,
*::before {
	-webkit-box-sizing: inherit;
	box-sizing: inherit;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Open Sans', 'Lato', 'SST W01 Roman', 'Helvetica Neue', Helvetica,
		Arial, sans-serif;
	height: 100%;
	position: relative;
	background-color: #edeef3;
	color: #212121;
	line-height: 1.6;
	font-size: 13px !important;
}

.containerHeight {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	margin-top: 1%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.headerColumn {
	display: flex;
	white-space: normal !important;
}
/* .sort-icon{
  margin-left: 0.3rem;
  margin-top: 0.3rem;
} */
.borderContainer {
	border: 2px solid #ddd;
	padding: 2%;
}

#root {
	height: 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.wrapper {
		height: 100% !important;
	}
}

.containerHeight {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	margin-top: 1%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	/* min-height: 76vh; */
}

.wrapper {
	background-color: #edeef3;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	min-height: 100vh;
	padding: 0% !important;
}

.floatLeft {
	float: left;
}

.floatRight {
	float: right;
	margin-top: 20px;
}

.required {
	color: #b50202;
}
.wrapperClass {
	width: 100%;
}

.model-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100%;
	overflow: hidden;
	height: 68% !important;
}
.center {
	justify-content: center;
}
.content-center {
	display: flex;
	align-items: center;
}
.column-header {
	height: 74px;
	overflow: hidden;
}
.selectAll {
	margin-left: -3px !important;
}
/* .form-display>fieldset{
  height: 60%;
} */
.priv_btns {
	margin: 0.3rem;
	height: 39px;
}
.expt-btns {
	margin: 0.3rem;
}

.form-vertical {
	width: 250px !important;
}
.form-group {
	margin-bottom: 0.3rem !important;
}
.table {
	margin-bottom: 0px !important;
}
.headerColumn {
	flex-flow: row;
	overflow: hidden;
	display: inline-flex;
	height: 37%;
	width: calc(100%);
	/* white-space: nowrap; */
	text-overflow: ellipsis;
	justify-content: center;
}
.customeHeader {
	display: inline-flex;
	justify-content: left;
	overflow: unset;
}

.headerAddOns {
	flex-flow: column;
	height: 65%;
}
.searchBox {
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	display: block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
}

/* @media screen and (max-width: 700px){
.headerColumn{
  display: inline-block;
}
} */
.datePicker {
	border-radius: 0.25rem;
	border-style: solid;
	border-width: 1px;
	border-color: hsl(0, 0%, 80%);
	background-color: hsl(0, 0%, 100%);
	align-items: center;
	min-height: 38px;
	line-height: 1.5;
}

.datePickerUploadError {
	border-radius: 0.25rem;
	border-style: solid;
	border-width: 1px;
	border-color: hsl(0, 0%, 80%);
	background-color: hsl(0, 0%, 100%);
	align-items: center;
	min-height: 38px;
	line-height: 1.5;
	width: 110%;
	/* margin-left:7% */
}

.invalid-feedbacck {
	display: block !important;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: #b50202;
}

.ReactVirtualized__Table__headerRow {
	background-color: #ddd;
}

.headerColumn h6 {
	font-size: 12px;
}
.header-font {
	font-size: 14px !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.table-wrapper {
	border: solid 2px #8f99a3;
	border-radius: 0.3rem;
	/* margin-bottom: 5px;
  padding-bottom: 2px; */
}
.table-row-count {
	display: flex;
	justify-content: space-between;
	font-weight: 600;
	background-color: #c6deff;
	border-radius: 0.3rem;
	height: 22px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.table-wrapper {
		height: 98.7% !important;
	}
}
.newClass {
	height: 40px !important;
}
.edit-btn {
	font-size: 10px !important;
	border-radius: 0.6rem !important;
}
.css-15k3avv {
	z-index: 20 !important;
}
.spinner-border-sm {
	width: 0.8rem !important;
	height: 0.8rem !important;
	border-width: 0.19em !important;
}
.text-secondary {
	color: #c8cbce !important;
}
.promo-tbl {
	margin-top: 0.3rem;
	margin-bottom: 0.3rem;
	overflow: auto;
}
.react-datepicker-popper {
	z-index: 4 !important;
}
.shiftRight {
	margin-right: 23px !important;
}
.shift {
	margin-right: 42px !important;
}
.form-display {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding-left: 2%;
	padding-top: 1%;
	border: 2px solid #8f99a3;
}

.form-vertical-field {
	border: 3px solid rgb(237, 238, 243);
	width: 280px !important;
	margin-right: 10px;
}

.form-input-with-icon {
	width: 165px !important;
}

.form-vertical-field-checkbox {
	margin-top: 10px;
	/* width: 100px;
    padding-left: 0px !important;
    height: 18px !important;
    margin-bottom: 43px !important;  */
}

.fixed-width-field {
	display: flex;
	justify-content: space-between;
}

.form-input-field {
	width: 175px !important;
}

/* .form-label {
  font-weight: bold;
} */
.form-select {
	width: 175px;
}

.form-input {
	width: 175px !important;
}

.form-label {
	font-weight: bold;
	white-space: nowrap;
}
.error-div {
	width: 100%;
}
.row-div {
	width: 100%;
	margin-bottom: 9px;
}
.check-box-credit {
	width: 100%;
	margin-top: -10px;
}
.inputTextSize-master {
	height: 38px !important;
}

.form-select-category {
	width: 200px;
}

.form-vertical-master {
	width: 200px;
	margin-right: 10px;
	margin-left: 10px;
}

.master-create-label {
	float: 'right';
	margin-bottom: 5px;
}

.fixedTable_CustomCell {
	overflow-x: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.block-with-text {
	/* hide text if it more than N lines  */
	overflow: hidden;
	/* for set '...' in absolute position */
	position: relative;
	/* use this value to count block height */
	line-height: 1.2em;
	/* max-height = line-height (1.2) * lines max number (3) */
	max-height: 3.6em;
	/* fix problem when last visible word doesn't adjoin right side  */
	text-align: justify;
	/* place for '...' */
	margin-right: -1em;
	padding-right: 1em;
	word-wrap: break-word;
}
/* create the ... */
.block-with-text:before {
	/* points in the end */
	content: '...';
	/* absolute position */
	position: absolute;
	/* set position to right bottom corner of block */
	right: 0;
	bottom: 0;
	word-wrap: break-word;
}
/* hide ... if we have text, which is less than or equal to max lines */
.block-with-text:after {
	/* points in the end */
	content: '';
	/* absolute position */
	position: absolute;
	/* set position to right bottom corner of text */
	right: 0;
	/* set width and height */
	width: 1em;
	height: 1em;
	margin-top: 0.2em;
	/* bg color = bg color under block */
	background: white;
	word-wrap: break-word;
}

.tool {
	width: 30%;
	word-wrap: break-word;
}

.template-link {
	display: block;
	width: 100%;
	padding: 0.05rem 0.5rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
	font-size: 12px;
}

.template-link:hover {
	text-decoration: none;
	color: #212529;
}

.formatNumber {
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	-webkit-transition: border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input.formatNumber:disabled {
	color: (rgb(84, 84, 84), rgb(170, 170, 170)) !important;
	cursor: default;
	background-color: (rgb(235, 235, 228), rgb(45, 45, 45)) !important;
}
.form-message {
	font-size: 10px;
	padding-top: 2px;
}
.form-message.invalid {
	color: #b50202 !important;
}
ul.options {
	display: inline-block;
	max-height: 150px;
	overflow-y: auto;
	overflow-x: hidden;
	position: absolute;
	background-color: #f9f9f9;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	width: 92%;
}

ul.options li {
	display: block;
	margin: 10px 0px;
	font-size: 0.7;
	width: 100%;
	border-radius: 1px;
}
ul.options li:hover {
	font-weight: bold;
	cursor: pointer;
	transition: 0.3s all;
}

ul.options li.option-active {
	background: whitesmoke;
	font-size: 1.5rem;
	color: #00b4cc;
}

.distCenter {
	text-align: center !important;
	margin-top: 20px;
}

.invalid-feedback {
	color: #b50202 !important;
}
.form-control.is-invalid {
	border-color: #b50202 !important;
}
